import OmGlobal from './OmGlobal';
import OmRow from './OmRow';
import OmColumn from './OmColumn';
import OmButton from './OmButton';
import OmProduct from './OmProduct';
import OmText from './OmText';
import OmCoupon from './OmCoupon';
import OmFeedback from './OmFeedback';
import OmInput from './OmInput';
import OmScratchCard from './OmScratchCard';
import OmSurvey from './OmSurvey';
import OmSocial from './OmSocial';
import OmInputPicker from './OmInputPicker';
import OmLuckyWheel from './OmLuckyWheel';
import OmPickAPresent from './OmPickAPresent';
import OmCountdown from './OmCountdown';
import OmDivider from './OmDivider';
import OmImage from './OmImage';

export default class ColorComponentsFactory {
  constructor(component, element, property, palette, globalStyle) {
    switch (component) {
      case 'global':
        return new OmGlobal(element, property, palette, globalStyle);
      case 'row':
        return new OmRow(element, property, palette);
      case 'column':
        return new OmColumn(element, property, palette);
      case 'button':
        return new OmButton(element, property, palette);
      case 'product':
        return new OmProduct(element, property, palette);
      case 'text':
        return new OmText(element, property, palette);
      case 'coupon':
        return new OmCoupon(element, property, palette);
      case 'feedback':
        return new OmFeedback(element, property, palette);
      case 'inputs':
        return new OmInput(element, property, palette);
      case 'scratchCard':
        return new OmScratchCard(element, property, palette);
      case 'survey':
        return new OmSurvey(element, property, palette);
      case 'social':
        return new OmSocial(element, property, palette);
      case 'input-picker':
        return new OmInputPicker(element, property, palette);
      case 'luckyWheel':
        return new OmLuckyWheel(element, property, palette);
      case 'pickapresent':
        return new OmPickAPresent(element, property, palette);
      case 'countdown':
        return new OmCountdown(element, property, palette);
      case 'divider':
        return new OmDivider(element, property, palette);
      case 'image':
        return new OmImage(element, property, palette);
      default:
        throw new Error(`ColorComponentsFactory: unknown component ${component}`);
    }
  }
}
